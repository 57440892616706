<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆列表</el-breadcrumb-item>
      <el-breadcrumb-item>车辆使用类型</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click='opentan()'>添加信息</el-button>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        style="width: 100%">
        <el-table-column
          prop="tname"
          label="车辆使用类型"
        >
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <!-- <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button> -->
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="车辆使用类型详情"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="150px" :rules="drules">
        <el-form-item label="使用类型名称" prop="tname">
          <el-input v-model="dform.tname"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch active-value='1' inactive-value='0' v-model="dform.status"></el-switch>
        </el-form-item>
        <el-form-item label="上级">
          <el-cascader @change='optpidfun' :options="tableData" clearable :props="casprops"></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { AddUseType, DelUseType, EditUseType, GetUseTypeList } from '../../../api/car'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      casprops: { value: 'id', label: 'tname', checkStrictly: true }, // 级联选择的配置
      dialogVisible: false, // 弹窗控制
      dform: {
        status: '1',
        pid: 0
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        tname: [
          { required: true, message: '请输入使用类型名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getusetypelistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getusetypelistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getusetypelistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      this.dform.status = this.dform.status +''
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delusetypefun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
    },
    // 选择级联中的值
    optpidfun(e) {
      console.log(e)
      this.dform.pid = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(this.dform)
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addusetypefun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editusetypefun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.dform.status = '1'
      this.dform.pid = 0
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取使用类型
    async getusetypelistfun() {
      const { data } = await GetUseTypeList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page
    },
    // 添加使用类型
    async addusetypefun(v) {
      const { data } = await AddUseType(v)
      this.getanpostfun(data, '添加', this.getusetypelistfun())
    },
    // 删除使用类型
    async delusetypefun(id) {
      const { data } = await DelUseType({ id })
      this.getanpostfun(data, '删除', this.getusetypelistfun())
    },
    async editusetypefun(ev) {
      const { data } = await EditUseType(ev)
      this.getanpostfun(data, '修改', this.getusetypelistfun())
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
